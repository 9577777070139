.hero {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-color: $secondary_green;
    border-bottom: .2rem solid $white;
    margin-bottom: 0;
    padding: 6rem 1.5rem;

    h1 {
        color: $white;
        line-height: 4rem;
        margin-bottom: 2rem;
    }

    p {
        color: $white;
        font-size: 1.6rem; 
    }

    @include min-screen($lg) {
        border-bottom: .1rem solid $white;
        padding: 10rem 0;

        h1 {
            line-height: 5.2rem;
            margin-bottom: 2rem;
        }

        p {
            font-size: 1.8rem; 
        }        
    }
}

.hero-cta {
    margin-top: 3rem;

    .btn {
        display: block;
        margin-bottom: 1.5rem;
    }

    @include min-screen($md) {
        .btn {
            display: inline-block;
            margin-right: 2.7rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.hero-large {
    @include min-screen($lg) {
        padding: 14.1rem 0 15.9rem;

        .container {
            max-width: 79rem;
        }

        h1 {
            margin-bottom: 3.5rem;
        }
    }
}

.hero-title {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 9.25rem 0;
    margin: 0;
    text-align: center;

    h1 {
        color: $white;
    }
 
    @include min-screen($lg) {
        padding: 9.25rem 0;
        text-align: left;
    }
} 

.jumbotron {
    background: $secondary_color;
}