.free-text {
    p {
        margin-bottom: 3.5rem;
    }

    img {
        margin-bottom: 2.5rem;
    }

    iframe {
        margin-bottom: 3.5rem;
        min-height: 42rem;
        width: 100%;
    }

    ul,
    ol {
        padding-left: 1.7rem;
        margin-bottom: 3.5rem;
    }

    blockquote {
        font-style: italic;
        margin-bottom: 3.5rem;
    }

    .btn,
    table {
        margin-bottom: 3.5rem;
    }

}