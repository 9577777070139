.timeline {
    .timeline-event {

        .event-title {
            background: $secondary-color;

            h2 {
                color: $white;
                line-height: 1;
                margin: 0;
            }
        }
    
        .event-year {
            background: $primary-color; 
        }
    
        .event-item:before {
            color: $secondary-color;
        }

    }
}