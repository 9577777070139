.cta-section {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    border-bottom: .2rem solid $white;
    padding: 6rem 1.5rem;

    @include min-screen($lg) {
        border-bottom: .1rem solid $white;
        padding: 10rem 0; 
    }

    h3 {
        margin-bottom: 1.5rem;

        @include min-screen($lg) {
            margin-bottom: 1.8rem;
        }
    }

    h2 {
        color: $white;
        line-height: 1.4;
        margin-bottom: 4rem;

        @include min-screen($lg) {
            line-height: 4.6rem;
            margin-bottom: 3.5rem;
        }        
    }    

    .cta-content {
        .btn {
            display: block;
        }

        @include min-screen($sm) {
            .btn {
                display: inline;
            }

            max-width: 60rem;
        }
    }
}