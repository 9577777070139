.form-control {
    color: #999999;
    font-size: 1.8rem;
    border: 0;
    border-radius: 0;
    height: auto;
    line-height: 1;
    padding: 1.9rem 2rem;
    text-align: left;
}

.form-control:focus {
    color: #999999;
}

.error {
    color: $button_negative_color;
}

// Form styles
.happyforms-styles.happyforms-form {

    h3.happyforms-form__title {
        font-family: $font__family_sans-serif;
        font-weight: bold;
        line-height: 5.4rem;
        margin-bottom: 2rem;
        text-transform: none;
    }

    .happyforms-flex {
        padding: 0;

        .happyforms-button--submit,
        .happyforms-button--submit:active,
        .happyforms-button--submit:focus {
            @extend .btn;
            @extend .btn-primary-alt;
        }

        .happyforms-button--submit:hover {
            text-transform: uppercase;
        }

        input[type=submit].happyforms-button--submit, 
        input[type=submit][disabled].happyforms-button--submit {
            text-transform: uppercase;
            padding: 1.9rem 2.5rem;
        }
    }

    & > .happyforms-message-notices .error {
        background: $primary_color;
    }

    .happyforms-message-notices h2 {
        line-height: 1;
    }
    
    .happyforms-form__part {
        padding: 0 1%;

        input[type=text],
        input[type=password],
        input[type=email],
        input[type=tel],
        input[type=number], 
        input[type=range],
        textarea,
        .option-label .border {
            border-radius: 0!important;
            padding: 12px 10px;
        }
    }

    .happyforms-part__label {
        .label, 
        .happyforms-optional {
            color: rgba(51, 51, 51, 0.75);
            font-weight: bold;
        }
    }

    .happyforms-part--choice .option-label {
        padding: 13px 10px;
    }

}
