.footer {
    font-size: 1.5rem;
    padding: 6rem 1.5rem 4rem;

    @include min-screen($lg) {
        padding: 6rem 0;
    }

    ul {
        padding-left: 0;
        margin: 0;
    }

    li {
        list-style-type: none;
    }

    a {
        color: $white;
        font-weight: 100;
    }

}

.footer-top {
    h5 {
        color: $white;
        margin-bottom: 2rem;

        @include min-screen($lg) {
            margin-bottom: 3rem;
        }
    }

    li {
        margin-bottom: .6rem;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    .menu {
        margin-bottom: 3rem;

        @include min-screen($md) {
            margin-bottom: 0;
        }
    }
}

.footer-social-links {
    li {
        display: inline-block;
        margin-right: 1rem;
        margin-bottom: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    i {
        color: $white;
        font-size: 3rem;
        transition: .4s ease;
        
        &:hover {
            color: $primary_color;
        }
    }
}

.footer-bottom {
    border-top: .1rem solid rgba(255,255,255,.5);
    color: rgba(255,255,255,.5);
    margin-top: 4rem;
    padding-top: 4rem;

    @include min-screen($lg) {
        padding-top: 3rem;
    }

    a {
        color: rgba(255,255,255,.5);
    }

    li {
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        @include min-screen($md) {
            margin-right: 3rem;

            &:last-child {
                margin-right: 0;
            }
        }

        @include min-screen($xl) {
            margin-bottom: 0;
        }
    }
}

.footer-logos {
    margin-top: 4rem;

    img {
        margin-bottom: 2rem;
        margin-right: .5rem;

        &:last-child {
            margin-right: 0;
        }

        &.rjc-logo,
        &.fairtrade,
        &.gold {
            max-width: 6rem;
        }
    
        &.iso-logo {
            max-width: 8.5rem;
        }
        
        @include min-screen($md) { 
            
            &.rjc-logo,
            &.fairtrade,
            &.gold {
                max-width: 5rem;
            }
        
            &.iso-logo {
                max-width: 10rem;
            }
        }

        @include min-screen($xl) {
            margin-bottom: 0;
        }
    }
}

.copyright {
    border-top: .1rem solid rgba(255,255,255,.5);
    padding-top: 4rem;
    margin-top: 4rem;

    p {
        font-weight: 100;
        margin: 0;
    }

    @include min-screen($lg) {
        border: 0;
        padding: 0;
        margin: 0;
        text-align: right;
    }
}