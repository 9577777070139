.listing-section {
    background: #f5f8fa;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 6rem 0 4.5rem;

    @include min-screen($lg) {
        padding: 7.5rem 0rem 5rem;
    }

    .intro {
        padding: 0 1.5rem;

        h3 {
            margin-bottom: 1.5rem;
    
            @include min-screen($lg) {
                margin-bottom: .3rem;
            }
        }
    
        h2 {
            margin-bottom: 2.5rem;
            
            @include min-screen($lg) {
                margin-bottom: 2.2rem;
            }
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }

    .item {
        margin-bottom: 3rem;
    }
    
    .card {
        border: 0;

        .card-image {
            overflow: hidden;

            img {
                transition: 0.4s ease;
                
        
                &:hover {
                    transform: scale(1.1);
                }
            }
        } 
    }

    .card-body {
        padding: 3rem;

        @include min-screen($lg) {
            box-shadow: 0px 5px 10px 0px rgba(17,17,17,0.1);
        }

        .date {
            color: $primary_color;
            font-size: 1.6rem;
            font-family: $font_family_monospace;
            line-height: 1;
            margin-bottom: .7rem;
        }

        h3 {
            color: $primary_color;
            font-size: 1.6rem;
            margin-bottom: .5rem;
            text-transform: uppercase;

            @include min-screen($lg) {
                margin-bottom: 1.2rem;
            }
        }
        
        h4 {
            line-height: 3rem;
            margin-bottom: 1rem;

            @include min-screen($lg) {
                margin-bottom: 1.5rem;
            }
            
            a {
                color: $text_color; 
            }
        }

        p {
            color: rgba(51,51,51,.75);
        }
    }
    
}
