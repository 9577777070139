.tabs-section {
    background-color: #f5f8fa;
    padding: 6rem 1.5rem;

    @include min-screen($lg) {
        padding: 8rem 0 8rem;
    }

    .free-text {
        padding: 0;
    }

    .nav-tabs .nav-link {
        color: $secondary_color;
        padding: 1rem 2rem;
    }

    .nav-tabs .nav-item.show .nav-link, 
    .nav-tabs .nav-link.active {
        background: $primary_color;
        color: $white;
    }

    a {
        color: $secondary_color;
    }

}