.breadcrumb-section {
    border-bottom: .2rem solid $border_grey;
    font-size: 1.5rem;
    line-height: 1;
    padding: 3rem 0;

    nav {
        padding: 0 1.5rem;

        @include min-screen($lg) {
            padding: 0;
        }
    }

    @include min-screen($lg) {
        border-bottom: .1rem solid $border_grey;
        padding: 2.85rem 0;
    }

    .breadcrumb {
        background: transparent;
        padding: 0;
        margin: 0;
    }

    a {
        color: $med_grey;
        margin-right: 1rem;
    }

    .breadcrumb_last {
        color: $secondary_color;
    }

    i {
        margin-right: 1.3rem;
    }

    span span span i:last-of-type {
        color: $secondary_color;
    }
}