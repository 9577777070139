$font__family_sans-serif: 'Lato', Arial, Verdana, Geneva, sans-serif;
$font_family_serif: 'Karla', serif;
$font_family_monospace: myriad-pro;
$font_family_base: $font__family_sans-serif;

$heading_font_family_base: $font__family_sans-serif;

$font-size-1: 4.2rem;
$font-size-2: 3.6rem;
$font-size-3: 2.4rem;
$font-size-4: 2.0rem;
$font-size-5: 1.8rem;
$font-size-6: 1.6rem;

$font-size-7: 1.7rem;
$font-size-8: 1.5rem;

html, body {
    height: 100%;
}

html {
    -webkit-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
}

body {
    color: rgba(51, 51, 51, .75);
    font-size: 1.6rem;
    font-family: $font__family_sans-serif;
    padding-top: 9rem;
}

main {
    display: block;
}

a {
    color: $secondary_color;
}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5 {
    color: $white;
    font-weight: bold;
    line-height: 1;

    &:first-child {
        margin-top: 0;
    }
}

h1, 
h4,
h5,
h6 {
    color: $text_color;
    margin-bottom: 3.5rem;

    @include min-screen($lg) {
        margin-bottom: 2rem;
    }
}

.h1, h1 {
    font-size: 2.8rem;

    @include min-screen($lg) {
        font-size: $font-size-1;
    }
}

.h2, h2 {
    color: $text_color;
    font-size: 2.2rem;
    line-height: 3rem;
    margin-bottom: 3.5rem;

    @include min-screen($lg) {
        font-size: $font-size-2;
        line-height: 5.4rem;
        margin-bottom: 2rem;
    }
}

.h3, h3 {
    color: $secondary_color;
    font-family: $font_family_monospace;
    font-size: 1.6rem;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 1rem;

    @include min-screen($lg) {
        font-size: $font-size-3;
        margin-bottom: 0;
    }
}

.h4, h4 {
    font-size: $font-size-4;
}

.h5, h5 {
    font-size: $font-size-5;
}

.h6, h6 {
    font-size: $font-size-6;
    font-weight: bold;
}

p {

}

.text-bold {
    font-weight: bold;
}