.subscriber-form {
    padding: 6rem 1.5rem 6rem;

    form {
        position: relative;
        width: 100%;
    }

    label.error {
        @include min-screen($lg) {
            left: 0;
            margin-top: 1rem;
            position: absolute;
            top: 100%;
        }
    }

    .title {
        margin-right: 0;
        margin-bottom: 2.8rem;

        @include min-screen(1200px) {
            margin-bottom: 0;
        }

        @include min-screen($xl) {
            margin-right: 9rem;
        }
    }

    .happyforms-styles.happyforms-form {
        align-items: flex-end;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;

        .happyforms-flex {
            margin: 0;

            @include min-screen($lg) {
                //flex-wrap: nowrap;
                justify-content: center;
            }

            @include min-screen($xl) {
                justify-content: flex-end;
            }
        }

        .happyforms-form__part {
            margin: 0;
            padding: 0;
            width: 100%;

            input[type="email"] {
                color: #999999;
                font-size: 1.8rem;
                border: 0;
                border-radius: 0;
                flex: 1;
                height: auto;
                line-height: 1;
                padding: 1.7rem 2rem;
                margin-bottom: 1.5rem;
                min-width: auto;
                width: 100%;                

                @include min-screen($lg) {
                    margin-bottom: 0;
                    width: 100%;
                }

                @include min-screen($lg) {
                    min-width: 37rem;
                }
            }

            @include min-screen($lg) {
                width: auto;
            }
        }        
    }

    .happyforms-part--submit {
        flex: none;
    }

    .happyforms-part--submit * {
        margin-right: 0;
    }

    .happyforms-styles.happyforms-form .happyforms-flex input[type=submit].happyforms-button--submit {
        display: block;
        font-weight: normal;
        width: 100%;

        @include min-screen($lg) {
            display: inline-block;
            width: auto;
        }
    }

    .happyforms-styles .happyforms-flex > .happyforms-message-notices .success,
    .happyforms-styles .happyforms-flex > .happyforms-message-notices .error {
        background: transparent!important;
        padding: 0!important;
    }


}