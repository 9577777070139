.btn {
    border: .2rem solid;
    border-radius: 0;
    font-size: 1.8rem;
    font-family: $font_family_serif;
    line-height: 1;
    padding: 1.9rem 2.5rem;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    transition: .4s ease;

    i {
        position: absolute;
        right: 1.8rem;
        top: 1.8rem;
    }
}

// Larger version of buttons (60px height)
.btn-lg {
    font-size: 1.8rem;
    padding: 1.90rem 2.5rem;
}

.btn-wider {
    padding-left: 7.45rem;
    padding-right: 7.45rem;
}

.btn-sm {
    padding: 1.4rem 2.5rem;

    i {
        top: 1.3rem;
    }
}

// Different button types
.btn-primary {
    border-color: $primary_color;
    background: $primary_color;
    color: $white;

    &:hover,
    &:active {
        border-color: $white;
        background: $white;
        color: $primary_color;
    }
}

.btn-primary-alt {
    @extend .btn-primary;
    border-color: $primary_color;

    &:hover,
    &:active {
        border-color: $secondary_color;
        background: $secondary_color;
        color: $white;
    }
}

.btn-primary-outline {
    border: .2rem solid $primary_color;
    color: $white;

    &:hover,
    &:active {
        background: $primary_color;
        color: $white;
    }
}

.btn-secondary-outline {
    border: .2rem solid $secondary_color;
    color: $secondary_color;

    &:hover,
    &:active {
        background: $secondary_color;
        color: $white;
    }
}