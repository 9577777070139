.text-primary {
  color: #f5b400 !important;
}

.text-secondary {
  color: #263878 !important;
}

.text-secondary-green {
  color: #2a3641 !important;
}

.text-tertiary-green {
  color: #1f2b37 !important;
}

.text-white {
  color: #FFFFFF !important;
}

html, body {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

body {
  color: rgba(51, 51, 51, 0.75);
  font-size: 1.6rem;
  font-family: "Lato", Arial, Verdana, Geneva, sans-serif;
  padding-top: 9rem;
}

main {
  display: block;
}

a {
  color: #263878;
}

.h1, h1, .h2, h2, .h3, h3, .h4, h4, .h5, h5 {
  color: #FFFFFF;
  font-weight: bold;
  line-height: 1;
}

.h1:first-child, h1:first-child, .h2:first-child, h2:first-child, .h3:first-child, h3:first-child, .h4:first-child, h4:first-child, .h5:first-child, h5:first-child {
  margin-top: 0;
}

h1,
h4,
h5,
h6 {
  color: #333333;
  margin-bottom: 3.5rem;
}

@media only screen and (min-width: 992px) {
  h1,
  h4,
  h5,
  h6 {
    margin-bottom: 2rem;
  }
}

.h1, h1 {
  font-size: 2.8rem;
}

@media only screen and (min-width: 992px) {
  .h1, h1 {
    font-size: 4.2rem;
  }
}

.h2, h2 {
  color: #333333;
  font-size: 2.2rem;
  line-height: 3rem;
  margin-bottom: 3.5rem;
}

@media only screen and (min-width: 992px) {
  .h2, h2 {
    font-size: 3.6rem;
    line-height: 5.4rem;
    margin-bottom: 2rem;
  }
}

.h3, h3 {
  color: #263878;
  font-family: myriad-pro;
  font-size: 1.6rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 992px) {
  .h3, h3 {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
}

.h4, h4 {
  font-size: 2rem;
}

.h5, h5 {
  font-size: 1.8rem;
}

.h6, h6 {
  font-size: 1.6rem;
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.bg-secondary-green {
  background-color: #2a3641;
}

.bg-tertiary-green {
  background-color: #1f2b37;
}

.bg-left {
  background-position: top left;
}

@media only screen and (min-width: 992px) {
  .bg-left {
    background-position: left center;
  }
}

.bg-center {
  background-position: top center;
}

@media only screen and (min-width: 992px) {
  .bg-center {
    background-position: center;
  }
}

.bg-right {
  background-position: top right;
}

@media only screen and (min-width: 992px) {
  .bg-right {
    background-position: right center;
  }
}

.btn, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:active,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:focus, .header .cta-link a, .cc-grower .cc-btn.cc-allow {
  border: .2rem solid;
  border-radius: 0;
  font-size: 1.8rem;
  font-family: "Karla", serif;
  line-height: 1;
  padding: 1.9rem 2.5rem;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  transition: .4s ease;
}

.btn i, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit i, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:active i, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:focus i, .header .cta-link a i, .cc-grower .cc-btn.cc-allow i {
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
}

.btn-lg {
  font-size: 1.8rem;
  padding: 1.90rem 2.5rem;
}

.btn-wider {
  padding-left: 7.45rem;
  padding-right: 7.45rem;
}

.btn-sm, .cc-grower .cc-btn.cc-allow {
  padding: 1.4rem 2.5rem;
}

.btn-sm i, .cc-grower .cc-btn.cc-allow i {
  top: 1.3rem;
}

.btn-primary, .btn-primary-alt, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:active,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:focus, .header .cta-link a, .cc-grower .cc-btn.cc-allow {
  border-color: #f5b400;
  background: #f5b400;
  color: #FFFFFF;
}

.btn-primary:hover, .btn-primary-alt:hover, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:hover, .header .cta-link a:hover, .cc-grower .cc-btn.cc-allow:hover, .btn-primary:active, .btn-primary-alt:active,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:active, .header .cta-link a:active, .cc-grower .cc-btn.cc-allow:active {
  border-color: #FFFFFF;
  background: #FFFFFF;
  color: #f5b400;
}

.btn-primary-alt, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:active,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:focus, .header .cta-link a {
  border-color: #f5b400;
}

.btn-primary-alt:hover, .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:hover, .header .cta-link a:hover, .btn-primary-alt:active,
.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:active, .header .cta-link a:active {
  border-color: #263878;
  background: #263878;
  color: #FFFFFF;
}

.btn-primary-outline {
  border: 0.2rem solid #f5b400;
  color: #FFFFFF;
}

.btn-primary-outline:hover, .btn-primary-outline:active {
  background: #f5b400;
  color: #FFFFFF;
}

.btn-secondary-outline {
  border: 0.2rem solid #263878;
  color: #263878;
}

.btn-secondary-outline:hover, .btn-secondary-outline:active {
  background: #263878;
  color: #FFFFFF;
}

.form-control {
  color: #999999;
  font-size: 1.8rem;
  border: 0;
  border-radius: 0;
  height: auto;
  line-height: 1;
  padding: 1.9rem 2rem;
  text-align: left;
}

.form-control:focus {
  color: #999999;
}

.error {
  color: #db0000;
}

.happyforms-styles.happyforms-form h3.happyforms-form__title {
  font-family: "Lato", Arial, Verdana, Geneva, sans-serif;
  font-weight: bold;
  line-height: 5.4rem;
  margin-bottom: 2rem;
  text-transform: none;
}

.happyforms-styles.happyforms-form .happyforms-flex {
  padding: 0;
}

.happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:hover {
  text-transform: uppercase;
}

.happyforms-styles.happyforms-form .happyforms-flex input[type=submit].happyforms-button--submit,
.happyforms-styles.happyforms-form .happyforms-flex input[type=submit][disabled].happyforms-button--submit {
  text-transform: uppercase;
  padding: 1.9rem 2.5rem;
}

.happyforms-styles.happyforms-form > .happyforms-message-notices .error {
  background: #f5b400;
}

.happyforms-styles.happyforms-form .happyforms-message-notices h2 {
  line-height: 1;
}

.happyforms-styles.happyforms-form .happyforms-form__part {
  padding: 0 1%;
}

.happyforms-styles.happyforms-form .happyforms-form__part input[type=text],
.happyforms-styles.happyforms-form .happyforms-form__part input[type=password],
.happyforms-styles.happyforms-form .happyforms-form__part input[type=email],
.happyforms-styles.happyforms-form .happyforms-form__part input[type=tel],
.happyforms-styles.happyforms-form .happyforms-form__part input[type=number],
.happyforms-styles.happyforms-form .happyforms-form__part input[type=range],
.happyforms-styles.happyforms-form .happyforms-form__part textarea,
.happyforms-styles.happyforms-form .happyforms-form__part .option-label .border {
  border-radius: 0 !important;
  padding: 12px 10px;
}

.happyforms-styles.happyforms-form .happyforms-part__label .label,
.happyforms-styles.happyforms-form .happyforms-part__label .happyforms-optional {
  color: rgba(51, 51, 51, 0.75);
  font-weight: bold;
}

.happyforms-styles.happyforms-form .happyforms-part--choice .option-label {
  padding: 13px 10px;
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-logo {
  height: 5rem;
  width: 18rem;
}

.footer {
  font-size: 1.5rem;
  padding: 6rem 1.5rem 4rem;
}

@media only screen and (min-width: 992px) {
  .footer {
    padding: 6rem 0;
  }
}

.footer ul {
  padding-left: 0;
  margin: 0;
}

.footer li {
  list-style-type: none;
}

.footer a {
  color: #FFFFFF;
  font-weight: 100;
}

.footer-top h5 {
  color: #FFFFFF;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 992px) {
  .footer-top h5 {
    margin-bottom: 3rem;
  }
}

.footer-top li {
  margin-bottom: .6rem;
}

.footer-top li:last-child {
  margin-bottom: 0;
}

.footer-top .menu {
  margin-bottom: 3rem;
}

@media only screen and (min-width: 768px) {
  .footer-top .menu {
    margin-bottom: 0;
  }
}

.footer-social-links li {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0;
}

.footer-social-links li:last-child {
  margin-right: 0;
}

.footer-social-links i {
  color: #FFFFFF;
  font-size: 3rem;
  transition: .4s ease;
}

.footer-social-links i:hover {
  color: #f5b400;
}

.footer-bottom {
  border-top: 0.1rem solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4rem;
  padding-top: 4rem;
}

@media only screen and (min-width: 992px) {
  .footer-bottom {
    padding-top: 3rem;
  }
}

.footer-bottom a {
  color: rgba(255, 255, 255, 0.5);
}

.footer-bottom li {
  margin-bottom: 1.5rem;
}

.footer-bottom li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .footer-bottom li {
    margin-right: 3rem;
  }
  .footer-bottom li:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .footer-bottom li {
    margin-bottom: 0;
  }
}

.footer-logos {
  margin-top: 4rem;
}

.footer-logos img {
  margin-bottom: 2rem;
  margin-right: .5rem;
}

.footer-logos img:last-child {
  margin-right: 0;
}

.footer-logos img.rjc-logo, .footer-logos img.fairtrade, .footer-logos img.gold {
  max-width: 6rem;
}

.footer-logos img.iso-logo {
  max-width: 8.5rem;
}

@media only screen and (min-width: 768px) {
  .footer-logos img.rjc-logo, .footer-logos img.fairtrade, .footer-logos img.gold {
    max-width: 5rem;
  }
  .footer-logos img.iso-logo {
    max-width: 10rem;
  }
}

@media only screen and (min-width: 1440px) {
  .footer-logos img {
    margin-bottom: 0;
  }
}

.copyright {
  border-top: 0.1rem solid rgba(255, 255, 255, 0.5);
  padding-top: 4rem;
  margin-top: 4rem;
}

.copyright p {
  font-weight: 100;
  margin: 0;
}

@media only screen and (min-width: 992px) {
  .copyright {
    border: 0;
    padding: 0;
    margin: 0;
    text-align: right;
  }
}

.header {
  padding: 0 3rem;
  position: fixed;
  top: 0;
  z-index: 2;
}

.header .container-fluid {
  padding: 0;
}

@media only screen and (min-width: 1440px) {
  .header .container-fluid {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.header .fa-bars {
  font-size: 2.513rem;
}

.header .cta-link {
  border: 0;
}

.header .cta-link a {
  display: block;
  font-size: 1.6rem;
  text-align: center;
  padding: 1.5rem 2.5rem;
}

@media only screen and (min-width: 992px) {
  .header .cta-link a {
    display: inline-block;
  }
}

.header .navbar-toggler {
  color: #002066;
  padding-right: 0;
  position: absolute;
  right: 3rem;
}

.header .header-logo {
  padding: 1.7rem 0;
}

.header .header-logo img {
  width: 18rem;
}

@media only screen and (min-width: 992px) {
  .header .header-logo {
    padding: 0;
  }
}

.main-nav {
  background: #1f2b37;
  height: calc(100vh - 9rem);
  left: 0;
  opacity: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-100%);
  visibility: hidden;
  width: 100%;
  z-index: 3;
}

@media only screen and (min-width: 992px) {
  .main-nav.nav {
    display: flex;
  }
}

@media only screen and (max-width: 992px) {
  .main-nav {
    transition: 0.4s ease-in;
  }
  .main-nav.is-active {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  .main-nav.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  .main-nav .is-open .sub-menu {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 992px) {
  .main-nav {
    background: #FFFFFF;
    height: auto;
    opacity: 1;
    overflow: visible;
    position: inherit;
    top: auto;
    transform: none;
    visibility: visible;
    width: auto;
  }
}

.main-nav-list {
  margin: 0;
  padding: 0;
}

.main-nav-list li {
  border-bottom: 0.1rem solid #FFFFFF;
  list-style-type: none;
  line-height: 1;
  padding: 2rem 3rem;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .main-nav-list li {
    padding: 0;
  }
}

.main-nav-list li.is-open > .sub-menu {
  height: auto;
  max-height: inherit;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

.main-nav-list li.is-open .fas {
  transform: rotate(-90deg);
}

.main-nav-list li .fas {
  font-size: 2.5rem;
  float: right;
  margin-left: 1.7rem;
  transition: 0.4s ease;
}

@media only screen and (min-width: 992px) {
  .main-nav-list li .fas {
    font-size: 1.6rem;
    float: none;
  }
}

.main-nav-list a {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 2rem;
  transition: 0.4s ease;
}

.main-nav-list a:active, .main-nav-list a:hover {
  color: #f5b400;
  text-decoration: none;
}

@media only screen and (min-width: 992px) {
  .main-nav-list a {
    font-size: 1.5rem;
  }
}

.main-nav-list .sub-menu {
  height: 0;
  left: 0;
  opacity: 0;
  padding: 0;
  top: 100%;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  visibility: hidden;
}

.main-nav-list .sub-menu img {
  display: none;
}

.main-nav-list .sub-menu li {
  border: 0;
  padding: 1rem 0;
}

.main-nav-list .sub-menu li a {
  font-size: 1.5rem;
}

.main-nav-list .sub-menu li:last-child {
  padding-bottom: 0;
}

@media only screen and (min-width: 992px) {
  .main-nav-list .sub-menu {
    align-items: center;
    background: #FFFFFF;
    border-top: 0.1rem solid #e3e3e3;
    box-shadow: 0px 5px 10px 0px rgba(17, 17, 17, 0.1);
    display: inline-flex;
    justify-content: center;
    height: auto;
    padding: 5.5rem 0;
    position: absolute;
    width: 100vw;
    z-index: 2;
  }
  .main-nav-list .sub-menu li {
    margin-right: 3rem;
    padding: 0;
  }
  .main-nav-list .sub-menu li a {
    display: flex;
    flex-direction: column;
    padding: 0;
    text-align: center;
  }
  .main-nav-list .sub-menu li img {
    display: inline-block;
    margin-bottom: 3rem;
  }
  .main-nav-list .sub-menu li:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) {
  .main-nav-list li {
    border: 0;
    padding: 2rem 0;
    width: auto;
  }
  .main-nav-list li a {
    position: relative;
  }
  .main-nav-list li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
  }
  .main-nav-list li.menu-item-has-children:hover > a:after {
    content: " ";
    background: url(../images/nav-arrow.png) no-repeat bottom center;
    display: block;
    height: 1.6rem;
    position: absolute;
    margin: 0 auto;
    top: 5.5rem;
    width: 100%;
    z-index: 3;
  }
  .main-nav-list > li {
    margin-left: 2.9rem;
  }
  .main-nav-list > li a {
    color: #26323d;
    display: inline-block;
    padding: 1.7rem 0;
  }
}

.menu-toggle-box {
  display: inline-block;
  outline: 0;
  position: relative;
  width: 2rem;
  height: 1.2rem;
}

.menu-toggle-inner:after,
.menu-toggle-inner:before {
  display: block;
  content: "";
  outline: 0;
}

.menu-toggle-slider {
  border: 0;
  background: transparent;
  outline: 0;
}

.menu-toggle-slider:focus {
  outline: 0;
}

.menu-toggle-slider.is-active .menu-toggle-inner {
  transform: translate3d(0, 0.5rem, 0) rotate(45deg);
}

.menu-toggle-slider .menu-toggle-inner:before {
  top: .5rem;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform,opacity;
}

.menu-toggle-slider .menu-toggle-inner:after {
  top: 1rem;
}

.menu-toggle-inner,
.menu-toggle-inner:after,
.menu-toggle-inner:before {
  background-color: #263878;
  height: .2rem;
  position: absolute;
  right: 0;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  width: 2rem;
}

.menu-toggle-slider.is-active .menu-toggle-inner:before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.menu-toggle-slider.is-active .menu-toggle-inner:after {
  transform: translate3d(0, -10px, 0) rotate(-90deg);
}

.subscriber-form {
  padding: 6rem 1.5rem 6rem;
}

.subscriber-form form {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .subscriber-form label.error {
    left: 0;
    margin-top: 1rem;
    position: absolute;
    top: 100%;
  }
}

.subscriber-form .title {
  margin-right: 0;
  margin-bottom: 2.8rem;
}

@media only screen and (min-width: 1200px) {
  .subscriber-form .title {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .subscriber-form .title {
    margin-right: 9rem;
  }
}

.subscriber-form .happyforms-styles.happyforms-form {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.subscriber-form .happyforms-styles.happyforms-form .happyforms-flex {
  margin: 0;
}

@media only screen and (min-width: 992px) {
  .subscriber-form .happyforms-styles.happyforms-form .happyforms-flex {
    justify-content: center;
  }
}

@media only screen and (min-width: 1440px) {
  .subscriber-form .happyforms-styles.happyforms-form .happyforms-flex {
    justify-content: flex-end;
  }
}

.subscriber-form .happyforms-styles.happyforms-form .happyforms-form__part {
  margin: 0;
  padding: 0;
  width: 100%;
}

.subscriber-form .happyforms-styles.happyforms-form .happyforms-form__part input[type="email"] {
  color: #999999;
  font-size: 1.8rem;
  border: 0;
  border-radius: 0;
  flex: 1;
  height: auto;
  line-height: 1;
  padding: 1.7rem 2rem;
  margin-bottom: 1.5rem;
  min-width: auto;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .subscriber-form .happyforms-styles.happyforms-form .happyforms-form__part input[type="email"] {
    margin-bottom: 0;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .subscriber-form .happyforms-styles.happyforms-form .happyforms-form__part input[type="email"] {
    min-width: 37rem;
  }
}

@media only screen and (min-width: 992px) {
  .subscriber-form .happyforms-styles.happyforms-form .happyforms-form__part {
    width: auto;
  }
}

.subscriber-form .happyforms-part--submit {
  flex: none;
}

.subscriber-form .happyforms-part--submit * {
  margin-right: 0;
}

.subscriber-form .happyforms-styles.happyforms-form .happyforms-flex input[type=submit].happyforms-button--submit {
  display: block;
  font-weight: normal;
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .subscriber-form .happyforms-styles.happyforms-form .happyforms-flex input[type=submit].happyforms-button--submit {
    display: inline-block;
    width: auto;
  }
}

.subscriber-form .happyforms-styles .happyforms-flex > .happyforms-message-notices .success,
.subscriber-form .happyforms-styles .happyforms-flex > .happyforms-message-notices .error {
  background: transparent !important;
  padding: 0 !important;
}

.hero {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-color: #2a3641;
  border-bottom: 0.2rem solid #FFFFFF;
  margin-bottom: 0;
  padding: 6rem 1.5rem;
}

.hero h1 {
  color: #FFFFFF;
  line-height: 4rem;
  margin-bottom: 2rem;
}

.hero p {
  color: #FFFFFF;
  font-size: 1.6rem;
}

@media only screen and (min-width: 992px) {
  .hero {
    border-bottom: 0.1rem solid #FFFFFF;
    padding: 10rem 0;
  }
  .hero h1 {
    line-height: 5.2rem;
    margin-bottom: 2rem;
  }
  .hero p {
    font-size: 1.8rem;
  }
}

.hero-cta {
  margin-top: 3rem;
}

.hero-cta .btn, .hero-cta .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .hero-cta .happyforms-button--submit, .hero-cta .header .cta-link a, .header .cta-link .hero-cta a, .hero-cta .cc-grower .cc-btn.cc-allow, .cc-grower .hero-cta .cc-btn.cc-allow {
  display: block;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .hero-cta .btn, .hero-cta .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .hero-cta .happyforms-button--submit, .hero-cta .header .cta-link a, .header .cta-link .hero-cta a, .hero-cta .cc-grower .cc-btn.cc-allow, .cc-grower .hero-cta .cc-btn.cc-allow {
    display: inline-block;
    margin-right: 2.7rem;
  }
  .hero-cta .btn:last-child, .hero-cta .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit:last-child, .happyforms-styles.happyforms-form .happyforms-flex .hero-cta .happyforms-button--submit:last-child, .hero-cta .header .cta-link a:last-child, .header .cta-link .hero-cta a:last-child, .hero-cta .cc-grower .cc-btn.cc-allow:last-child, .cc-grower .hero-cta .cc-btn.cc-allow:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) {
  .hero-large {
    padding: 14.1rem 0 15.9rem;
  }
  .hero-large .container {
    max-width: 79rem;
  }
  .hero-large h1 {
    margin-bottom: 3.5rem;
  }
}

.hero-title {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 9.25rem 0;
  margin: 0;
  text-align: center;
}

.hero-title h1 {
  color: #FFFFFF;
}

@media only screen and (min-width: 992px) {
  .hero-title {
    padding: 9.25rem 0;
    text-align: left;
  }
}

.jumbotron {
  background: #263878;
}

.banner-section {
  background-repeat: no-repeat;
  background-size: auto 25rem;
  border-bottom: 0.2rem solid #FFFFFF;
  padding: 6rem 1.5rem 6rem;
}

.banner-section.has-image {
  padding-top: 26rem;
}

@media only screen and (min-width: 992px) {
  .banner-section {
    background-size: auto;
    border-bottom: 0.1rem solid #FFFFFF;
    padding: 8rem 0;
  }
  .banner-section.has-image {
    padding-top: 8rem;
  }
}

.banner-section h2 {
  color: #FFFFFF;
  margin-bottom: 3rem;
}

@media only screen and (min-width: 992px) {
  .banner-section h2 {
    line-height: 4.6rem;
    margin-bottom: 2.5rem;
  }
}

.banner-section h3 {
  color: #FFFFFF;
  margin-bottom: 1.1rem;
}

@media only screen and (min-width: 992px) {
  .banner-section h3 {
    margin-bottom: .2rem;
  }
}

.banner-section p {
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 3.7rem;
}

.banner-section .btn, .banner-section .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .banner-section .happyforms-button--submit, .banner-section .header .cta-link a, .header .cta-link .banner-section a, .banner-section .cc-grower .cc-btn.cc-allow, .cc-grower .banner-section .cc-btn.cc-allow {
  display: block;
}

@media only screen and (min-width: 992px) {
  .banner-section .btn, .banner-section .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .banner-section .happyforms-button--submit, .banner-section .header .cta-link a, .header .cta-link .banner-section a, .banner-section .cc-grower .cc-btn.cc-allow, .cc-grower .banner-section .cc-btn.cc-allow {
    display: inline-block;
  }
}

.listing-section {
  background: #f5f8fa;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 6rem 0 4.5rem;
}

@media only screen and (min-width: 992px) {
  .listing-section {
    padding: 7.5rem 0rem 5rem;
  }
}

.listing-section .intro {
  padding: 0 1.5rem;
}

.listing-section .intro h3 {
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 992px) {
  .listing-section .intro h3 {
    margin-bottom: .3rem;
  }
}

.listing-section .intro h2 {
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 992px) {
  .listing-section .intro h2 {
    margin-bottom: 2.2rem;
  }
}

@media only screen and (min-width: 992px) {
  .listing-section .intro {
    padding: 0;
  }
}

.listing-section .item {
  margin-bottom: 3rem;
}

.listing-section .card {
  border: 0;
}

.listing-section .card .card-image {
  overflow: hidden;
}

.listing-section .card .card-image img {
  transition: 0.4s ease;
}

.listing-section .card .card-image img:hover {
  transform: scale(1.1);
}

.listing-section .card-body {
  padding: 3rem;
}

@media only screen and (min-width: 992px) {
  .listing-section .card-body {
    box-shadow: 0px 5px 10px 0px rgba(17, 17, 17, 0.1);
  }
}

.listing-section .card-body .date {
  color: #f5b400;
  font-size: 1.6rem;
  font-family: myriad-pro;
  line-height: 1;
  margin-bottom: .7rem;
}

.listing-section .card-body h3 {
  color: #f5b400;
  font-size: 1.6rem;
  margin-bottom: .5rem;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) {
  .listing-section .card-body h3 {
    margin-bottom: 1.2rem;
  }
}

.listing-section .card-body h4 {
  line-height: 3rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 992px) {
  .listing-section .card-body h4 {
    margin-bottom: 1.5rem;
  }
}

.listing-section .card-body h4 a {
  color: #333333;
}

.listing-section .card-body p {
  color: rgba(51, 51, 51, 0.75);
}

.cta-section {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  border-bottom: 0.2rem solid #FFFFFF;
  padding: 6rem 1.5rem;
}

@media only screen and (min-width: 992px) {
  .cta-section {
    border-bottom: 0.1rem solid #FFFFFF;
    padding: 10rem 0;
  }
}

.cta-section h3 {
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 992px) {
  .cta-section h3 {
    margin-bottom: 1.8rem;
  }
}

.cta-section h2 {
  color: #FFFFFF;
  line-height: 1.4;
  margin-bottom: 4rem;
}

@media only screen and (min-width: 992px) {
  .cta-section h2 {
    line-height: 4.6rem;
    margin-bottom: 3.5rem;
  }
}

.cta-section .cta-content .btn, .cta-section .cta-content .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .cta-section .cta-content .happyforms-button--submit, .cta-section .cta-content .header .cta-link a, .header .cta-link .cta-section .cta-content a, .cta-section .cta-content .cc-grower .cc-btn.cc-allow, .cc-grower .cta-section .cta-content .cc-btn.cc-allow {
  display: block;
}

@media only screen and (min-width: 576px) {
  .cta-section .cta-content {
    max-width: 60rem;
  }
  .cta-section .cta-content .btn, .cta-section .cta-content .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .cta-section .cta-content .happyforms-button--submit, .cta-section .cta-content .header .cta-link a, .header .cta-link .cta-section .cta-content a, .cta-section .cta-content .cc-grower .cc-btn.cc-allow, .cc-grower .cta-section .cta-content .cc-btn.cc-allow {
    display: inline;
  }
}

.free-text p {
  margin-bottom: 3.5rem;
}

.free-text img {
  margin-bottom: 2.5rem;
}

.free-text iframe {
  margin-bottom: 3.5rem;
  min-height: 42rem;
  width: 100%;
}

.free-text ul,
.free-text ol {
  padding-left: 1.7rem;
  margin-bottom: 3.5rem;
}

.free-text blockquote {
  font-style: italic;
  margin-bottom: 3.5rem;
}

.free-text .btn, .free-text .happyforms-styles.happyforms-form .happyforms-flex .happyforms-button--submit, .happyforms-styles.happyforms-form .happyforms-flex .free-text .happyforms-button--submit, .free-text .header .cta-link a, .header .cta-link .free-text a, .free-text .cc-grower .cc-btn.cc-allow, .cc-grower .free-text .cc-btn.cc-allow,
.free-text table {
  margin-bottom: 3.5rem;
}

.breadcrumb-section {
  border-bottom: 0.2rem solid #e3e3e3;
  font-size: 1.5rem;
  line-height: 1;
  padding: 3rem 0;
}

.breadcrumb-section nav {
  padding: 0 1.5rem;
}

@media only screen and (min-width: 992px) {
  .breadcrumb-section nav {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) {
  .breadcrumb-section {
    border-bottom: 0.1rem solid #e3e3e3;
    padding: 2.85rem 0;
  }
}

.breadcrumb-section .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}

.breadcrumb-section a {
  color: #666666;
  margin-right: 1rem;
}

.breadcrumb-section .breadcrumb_last {
  color: #263878;
}

.breadcrumb-section i {
  margin-right: 1.3rem;
}

.breadcrumb-section span span span i:last-of-type {
  color: #263878;
}

.specifications-section {
  background: #f5f8fa;
  padding: 6rem 1.5rem 4rem;
}

@media only screen and (min-width: 992px) {
  .specifications-section {
    padding: 8rem 0 6rem;
  }
}

.specifications-section h2 {
  margin-bottom: 3rem;
}

@media only screen and (min-width: 992px) {
  .specifications-section h2 {
    margin-bottom: 2.5rem;
  }
}

.specifications-section h3 {
  margin-bottom: 1.1rem;
}

@media only screen and (min-width: 992px) {
  .specifications-section h3 {
    margin-bottom: .2rem;
  }
}

.specifications-section p {
  margin-bottom: 2rem;
}

.specifications-section strong {
  color: #333333;
}

.tabs-section {
  background-color: #f5f8fa;
  padding: 6rem 1.5rem;
}

@media only screen and (min-width: 992px) {
  .tabs-section {
    padding: 8rem 0 8rem;
  }
}

.tabs-section .free-text {
  padding: 0;
}

.tabs-section .nav-tabs .nav-link {
  color: #263878;
  padding: 1rem 2rem;
}

.tabs-section .nav-tabs .nav-item.show .nav-link,
.tabs-section .nav-tabs .nav-link.active {
  background: #f5b400;
  color: #FFFFFF;
}

.tabs-section a {
  color: #263878;
}

.pull-quote-content .larger {
  font-size: 2rem;
}

.accordion-section .card {
  margin-bottom: 1.5rem;
}

.accordion-section .card:last-of-type {
  margin-bottom: 0;
}

.accordion-section .card-header {
  background: #263878;
  padding: 0;
}

.accordion-section .card-header a {
  color: #FFFFFF;
  display: block;
  padding: 2rem;
}

.accordion-section .card-header a i {
  float: right;
  font-size: 2.4rem;
  transition: .4s ease;
}

.accordion-section .card-header [aria-expanded='true'] i {
  transform: rotate(-90deg);
}

.accordion-section .card-body {
  padding: 2rem;
}

.timeline .timeline-event .event-title {
  background: #263878;
}

.timeline .timeline-event .event-title h2 {
  color: #FFFFFF;
  line-height: 1;
  margin: 0;
}

.timeline .timeline-event .event-year {
  background: #f5b400;
}

.timeline .timeline-event .event-item:before {
  color: #263878;
}

.half-layout .free-text {
  padding: 0;
}

.generic-content-section {
  background: #f5f8fa;
  padding: 6rem 1.5rem 6rem;
}

@media only screen and (min-width: 992px) {
  .generic-content-section {
    padding: 8rem 0 6rem;
  }
}

.cc-grower {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.cc-btn {
  font-weight: normal;
}

.cc-grower {
  max-height: inherit !important;
}

.cc-window.cc-window.cc-banner {
  padding: 2rem 3rem;
  flex-direction: column;
}

@media only screen and (min-width: 992px) {
  .cc-window.cc-window.cc-banner {
    flex-direction: row;
    padding: 2rem 2.5rem;
  }
}

.cc-compliance {
  margin-top: 2rem;
}

@media only screen and (min-width: 992px) {
  .cc-compliance {
    margin-top: 0;
  }
}
