.specifications-section {
    background: #f5f8fa;
    padding: 6rem 1.5rem 4rem;

    @include min-screen($lg) {
        padding: 8rem 0 6rem;
    }

    h2 {
        margin-bottom: 3rem;
        
        @include min-screen($lg) {
            margin-bottom: 2.5rem;
        }
    }

    h3 {
        margin-bottom: 1.1rem;

        @include min-screen($lg) {
            margin-bottom: .2rem;
        }
    }

    p {
        margin-bottom: 2rem;
    }

    strong {
        color: $text_color;
    }
}