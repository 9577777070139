.icon {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}
 
.icon-logo {
    height: 5rem;
    width: 18rem;
}