.accordion-section {
    .card {
        margin-bottom: 1.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .card-header {
        background: $secondary_color;
        padding: 0;

        a {
            color: $white;
            display: block;
            padding: 2rem;

            i {
                float: right;
                font-size: 2.4rem;
                transition: .4s ease;
            }
        }

        [aria-expanded='true'] {
            i {
                transform: rotate(-90deg);
            }
        }
    }

    .card-body {
        padding: 2rem;
    }
}