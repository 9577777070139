.banner-section {
    background-repeat: no-repeat;
    background-size: auto 25rem;
    border-bottom: .2rem solid $white;
    padding: 6rem 1.5rem 6rem;

    &.has-image {
        padding-top: 26rem;
    }

    @include min-screen($lg) {
        background-size: auto;
        border-bottom: .1rem solid $white;
        padding: 8rem 0;

        &.has-image {
            padding-top: 8rem;
        }
    }

    h2 {
        color: $white;
        margin-bottom: 3rem;

        @include min-screen($lg) {
            line-height: 4.6rem;
            margin-bottom: 2.5rem;
        }        
    }

    h3 {
        color: $white;
        margin-bottom: 1.1rem;

        @include min-screen($lg) {
            margin-bottom: .2rem;
        }
    }

    p {
        color: rgba(255,255,255,.75);
        margin-bottom: 3.7rem;
    }

    .btn {
        display: block;

        @include min-screen($lg) {
            display: inline-block;
        }
    }
}