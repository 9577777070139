.cc-grower {
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.cc-grower .cc-btn.cc-allow {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sm;
}

.cc-btn {
    font-weight: normal;
}

.cc-grower {
    max-height: inherit!important;
}

.cc-window.cc-window.cc-banner {
    padding: 2rem 3rem;
    flex-direction: column;

    @include min-screen($lg) {
        flex-direction: row;
        padding: 2rem 2.5rem;
    }
}

.cc-compliance {
    margin-top: 2rem;

    @include min-screen($lg) {
        margin-top: 0;
    }
}