.header {
    padding: 0 3rem;
    position: fixed;
    top: 0;
    z-index: 2;

    .container-fluid {  
        padding: 0;

        @include min-screen($xl) {
            max-width: $xl;
            margin: 0 auto;
        }        
    }

    .fa-bars {
        font-size: 2.513rem;
    }
    
    .cta-link {
        border: 0;
 
        a {
            display: block;
            @extend .btn;
            @extend .btn-primary-alt;
            font-size: 1.6rem;
            text-align: center;
            padding: 1.5rem 2.5rem;
    
            @include min-screen($lg) {
                display: inline-block;
            }
        }
    }

    .navbar-toggler {
        color: #002066;
        padding-right: 0;
        position: absolute;
        right: 3rem;
    }

    .header-logo {
        padding: 1.7rem 0;

        img {
            width: 18rem;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }
}

//
// Menu Styles
//
.main-nav {
    background: #1f2b37; 
    height: calc(100vh - 9rem);
    left: 0;
    opacity: 0;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 100%;
    transform: translateX(-100%);
    visibility: hidden;
    width: 100%;
    z-index: 3;
    
    &.nav {
        @include min-screen($lg) {
            display: flex;
        }
    }

    @include max-screen($lg) {
        // Mobile menu active state
        &.is-active {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
        }

        transition: 0.4s ease-in;

        // Mobile menu active state
        &.show {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
        }

        .is-open .sub-menu {
            margin-top: 1rem;
        }
    }

    @include min-screen($lg) {
        background: $white;
        height: auto;
        opacity: 1;
        overflow: visible;
        position: inherit;
        top: auto;
        transform: none;
        visibility: visible;
        width: auto; 
    }

}

.main-nav-list {
    margin: 0;
    padding: 0;

    li {
        border-bottom: .1rem solid $white;
        list-style-type: none;
        line-height: 1;
        padding: 2rem 3rem;
        width: 100%;

        @include min-screen($lg) {
            padding: 0;
        }

        &.is-open {
            > .sub-menu {
                height: auto;
                max-height: inherit;
                opacity: 1;
                overflow: visible;
                visibility: visible;
            }

            .fas {
                transform: rotate(-90deg);
            }
        }

        .fas {
            font-size: 2.5rem;
            float: right;
            margin-left: 1.7rem;
            transition: 0.4s ease;
    
            @include min-screen($lg) {
                font-size: 1.6rem;
                float: none;
            }
        }
    }

    a {
        color: $white;
        font-weight: bold;
        font-size: 2rem;
        transition: 0.4s ease;

        &:active,
        &:hover {
            color: $primary_color;
            text-decoration: none;
        }        

        @include min-screen($lg) {
            font-size: 1.5rem;
        }
    }

    // Dropdown menu
    .sub-menu {
        height: 0;
        left: 0;
        opacity: 0;
        padding: 0;
        top: 100%;
        -webkit-transition: .2s ease-out;
        transition: .2s ease-out;
        visibility: hidden;

        img {
            display: none;
        }

        li {
            border: 0;
            padding: 1rem 0;

            a {
                font-size: 1.5rem;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        @include min-screen($lg) {
            align-items: center;
            background: $white;
            border-top: .1rem solid $border_grey;
            box-shadow: 0px 5px 10px 0px rgba(17, 17, 17, 0.1);
            display: inline-flex;
            justify-content: center;
            height: auto;
            padding: 5.5rem 0;
            position: absolute;
            width: 100vw;
            z-index: 2;

            li {
                margin-right: 3rem;
                padding: 0;

                a {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    text-align: center;
                }

                img {
                    display: inline-block;
                    margin-bottom: 3rem;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    
    @include min-screen($lg) {
        li {
            border: 0;
            padding: 2rem 0;
            width: auto;

            a {
                position: relative;
            }

            &:hover > .sub-menu {
                opacity: 1;
                visibility: visible;
            }

            &.menu-item-has-children:hover > a:after {
                content: " ";
                background: url(../images/nav-arrow.png) no-repeat bottom center;
                display: block;
                height: 1.6rem;
                position: absolute;
                margin: 0 auto;
                top: 5.5rem;
                width: 100%;
                z-index: 3;
            }

        }

        & > li {
            margin-left: 2.9rem;

            a {
                color: #26323d;
                display: inline-block;
                padding: 1.7rem 0;
            }
        }
    }
}

.menu-toggle-box {
    display: inline-block;
    outline: 0;
    position: relative;
    width: 2rem;
    height: 1.2rem;
}

.menu-toggle-inner:after, 
.menu-toggle-inner:before {
    display: block;
    content: "";
    outline: 0;
}

.menu-toggle-slider {
    border: 0;
    background: transparent;
    outline: 0;

    &:focus {
        outline: 0;
    }
}

.menu-toggle-slider.is-active .menu-toggle-inner {
    transform: translate3d(0, .5rem, 0) rotate(45deg);
}

.menu-toggle-slider .menu-toggle-inner:before {
    top: .5rem;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform,opacity;
}

.menu-toggle-slider .menu-toggle-inner:after {
    top: 1rem;
}

.menu-toggle-inner, 
.menu-toggle-inner:after, 
.menu-toggle-inner:before {
    background-color: $secondary_color;
    height: .2rem;
    position: absolute;
    right: 0;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    width: 2rem;

}

.menu-toggle-slider.is-active .menu-toggle-inner:before {
    transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
    opacity: 0;
}

.menu-toggle-slider.is-active .menu-toggle-inner:after {
    transform: translate3d(0,-10px,0) rotate(-90deg);
}