$primary_color: #f5b400;
$primary_color_dark: darken($primary_color, 35%);
$primary_color_darker: darken($primary_color, 17.5%);
$primary_color_lighter: lighten($primary_color, 17.5%);
$primary_color_light: lighten($primary_color, 35%);

$secondary_color: #263878;
$secondary_color_dark: darken($secondary_color, 35%);
$secondary_color_darker: darken($secondary_color, 17.5%);
$secondary_color_lighter: lighten($secondary_color, 17.5%);
$secondary_color_light: lighten($secondary_color, 35%);

$text_color: #333333;
$text_color_dark: darken($text_color, 15%);
$text_color_light: lighten($text_color, 15%);

$button_positive_color: #469132;
$button_negative_color: #db0000;
$button_optional_color: #d87a00;

$white: #FFFFFF;
$black: #000000;

$secondary_green: #2a3641;
$tertiary_green: #1f2b37;

$med_grey: #666666;
$border_grey: #e3e3e3;
$section_bg: #f5f8fa;

.text-primary {
    color: $primary_color!important;
}

.text-secondary {
    color: $secondary_color!important;
}

.text-secondary-green {
    color: $secondary_green!important;
}

.text-tertiary-green {
    color: $tertiary_green!important;
}

.text-white {
    color: $white!important;
}