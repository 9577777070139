.bg-secondary-green {
    background-color: $secondary_green;
}

.bg-tertiary-green {
    background-color: $tertiary_green;
}

.bg-left {
    background-position: top left;

    @include min-screen($lg) {
        background-position: left center;
    }
}

.bg-center {
    background-position: top center;

    @include min-screen($lg) {
        background-position: center;
    }
}

.bg-right {
    background-position: top right;
    
    @include min-screen($lg) {
        background-position: right center;
    }
}